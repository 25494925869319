import request from '@/utils/request'

// 查询客户短信下行记录
export function findAgentsSmsDeliveryRecords(params) {
  return request({
    url: `/customer/agents_sms_delivery_records`,
    method: 'get',
    params: params
  })
}

// 查询单个短信下行记录
export function findAgentsSmsDeliveryRecord(id) {
  return request({
    url: `/customer/agents_sms_delivery_records/${id}`,
    method: 'get'
  })
}
